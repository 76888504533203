<svg
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 480 480"
  style="enable-background:new 0 0 480 480;"
  xml:space="preserve"
  fill="currentColor"
>
  <g>
    <g>
      <g>
        <path
          d="M16,416v-32h16v-16H16v-32h32v-16H16v-32h16v-16H16v-32h32v-16H16v-32h16v-16H16v-32h32v-16H16V96h16V80H16V48h32V32H16
				V0H0v424c0,4.418,3.582,8,8,8h472v-16H16z"
        />
        <rect x="80" y="448" width="16" height="32" />
        <rect x="112" y="448" width="16" height="32" />
        <rect x="192" y="448" width="16" height="32" />
        <rect x="224" y="448" width="16" height="32" />
        <rect x="304" y="448" width="16" height="32" />
        <rect x="336" y="448" width="16" height="32" />
        <rect x="416" y="448" width="16" height="32" />
        <rect x="448" y="448" width="16" height="32" />
        <path
          d="M86.525,394.897c5.208,3.357,11.279,5.13,17.475,5.103c17.673,0,32-14.327,32-32c-0.035-5.268-1.388-10.444-3.936-15.056
				l64.048-48.04c13.712,10.966,33.719,8.74,44.685-4.972c0.968-1.21,1.846-2.49,2.627-3.828l52.632,15.096
				c0,0.264-0.056,0.528-0.056,0.8c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32v-0.056
				c-0.064-6.877-2.342-13.551-6.496-19.032l71.2-88.976c4.673,2.627,9.936,4.025,15.296,4.064
				c17.805,0.133,32.347-14.194,32.479-31.999s-14.194-32.347-31.999-32.479c-17.805-0.133-32.347,14.194-32.479,31.999
				c-0.046,6.172,1.681,12.228,4.975,17.447l-72,90c-1.296-0.602-2.633-1.112-4-1.528c-13.992-4.038-28.945,1.88-36.384,14.4
				l-52.648-15.04c0-0.264,0.056-0.528,0.056-0.8c0.023-14.266-9.359-26.84-23.04-30.88c-16.833-5.039-34.565,4.523-39.604,21.356
				c-2.129,7.113-1.704,14.747,1.203,21.58l-65.36,49.048c-14.854-9.575-34.659-5.296-44.234,9.559
				C67.391,365.517,71.671,385.321,86.525,394.897z M440,160c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16
				S431.163,160,440,160z M312.64,307.68c2.465-8.403,11.276-13.216,19.678-10.751c8.403,2.465,13.216,11.276,10.751,19.678
				c-2.465,8.403-11.276,13.216-19.678,10.751c-6.824-2.002-11.481-8.303-11.391-15.414
				C311.993,310.498,312.209,309.06,312.64,307.68z M200.638,275.368c0.001-0.003,0.002-0.006,0.003-0.008
				c1.174-4.081,3.934-7.521,7.664-9.552c7.747-4.251,17.473-1.416,21.724,6.331c1.312,2.391,1.991,5.078,1.972,7.805v0.112
				c0.007,1.446-0.209,2.884-0.64,4.264c-2.472,8.484-11.353,13.357-19.837,10.885C203.039,292.734,198.166,283.852,200.638,275.368
				z M104,352c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16S95.163,352,104,352z"
        />
        <path
          d="M104,280c17.593,0.081,31.921-14.116,32.002-31.71c0.037-7.966-2.912-15.656-8.266-21.554l78.488-124.424
				c9.917,3.437,20.903,1.556,29.112-4.984l63.4,49.872c-7.149,16.109,0.115,34.964,16.225,42.113
				c4.105,1.822,8.548,2.756,13.039,2.743h0.072c17.667-0.04,31.959-14.389,31.928-32.056c-0.007-2.875-0.406-5.736-1.184-8.504
				l57.648-33.792c6.047,6.601,14.583,10.367,23.536,10.384h0.12c17.7-0.04,32.016-14.42,31.976-32.12
				c-0.04-17.7-14.42-32.016-32.12-31.976c-17.69,0.04-32.002,14.406-31.976,32.096c0.029,2.524,0.348,5.037,0.952,7.488
				L350.8,137.6c-6.019-6.101-14.23-9.538-22.8-9.544h-0.088c-6.956,0.044-13.703,2.377-19.2,6.64L245.288,84.8
				c7.137-16.168-0.184-35.06-16.351-42.198s-35.06,0.184-42.198,16.351c-5.188,11.752-2.845,25.467,5.949,34.83L114.4,217.88
				c-3.337-1.206-6.852-1.841-10.4-1.88c-17.673,0-32,14.327-32,32S86.327,280,104,280z M440,80c8.837,0,16,7.163,16,16
				c0.031,8.836-7.107,16.025-15.944,16.056c0,0,0,0,0,0H440c-5.725,0.008-11.018-3.042-13.88-8
				c-1.357-2.471-2.085-5.237-2.12-8.056C424,87.163,431.163,80,440,80z M328,144.056c8.837,0,16,7.163,16,16
				c0,8.837-7.163,16-16,16s-16-7.163-16-16C312,151.219,319.163,144.056,328,144.056z M216,56c8.837,0,16,7.163,16,16
				s-7.163,16-16,16s-16-7.163-16-16S207.163,56,216,56z M104,232c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16
				S95.163,232,104,232z"
        />
      </g>
    </g>
  </g>
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
</svg>
