<svg
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 480 480"
  style="enable-background:new 0 0 480 480;"
  xml:space="preserve"
  fill="currentColor"
>
  <g>
    <g>
      <g>
        <path
          d="M16,416v-32h16v-16H16v-32h32v-16H16v-32h16v-16H16v-32h32v-16H16v-32h16v-16H16v-32h32v-16H16V96h16V80H16V48h32V32H16
				V0H0v424c0,4.418,3.582,8,8,8h472v-16H16z"
        />
        <rect x="80" y="448" width="16" height="32" />
        <rect x="112" y="448" width="16" height="32" />
        <rect x="192" y="448" width="16" height="32" />
        <rect x="224" y="448" width="16" height="32" />
        <rect x="304" y="448" width="16" height="32" />
        <rect x="336" y="448" width="16" height="32" />
        <rect x="416" y="448" width="16" height="32" />
        <rect x="448" y="448" width="16" height="32" />
        <rect x="80" y="330" width="40" height="90" />
        <rect x="192" y="250" width="40" height="170" />
        <rect x="304" y="100" width="40" height="320" />
        <rect x="416" y="200" width="40" height="220" />
      </g>
    </g>
  </g>
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
  <g />
</svg>
