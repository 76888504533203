<script>
  export let size = 24;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 315 259"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  xmlns:serif="http://www.serif.com/"
  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
>
  <g transform="matrix(1,0,0,1,-788.199,-241.475)">
    <g transform="matrix(288,0,0,288,779.301,454.918)">
      <path
        d="M0.386,0.158L0.386,0.045C0.302,0.045 0.285,0.012 0.285,-0.056L0.285,-0.16C0.285,-0.214 0.261,-0.263 0.158,-0.284L0.158,-0.299C0.261,-0.32 0.285,-0.369 0.285,-0.423L0.285,-0.527C0.285,-0.596 0.302,-0.628 0.386,-0.628L0.386,-0.741C0.243,-0.741 0.14,-0.717 0.14,-0.542L0.14,-0.463C0.14,-0.391 0.113,-0.36 0.031,-0.36L0.031,-0.223C0.113,-0.223 0.14,-0.192 0.14,-0.12L0.14,-0.041C0.14,0.134 0.243,0.158 0.386,0.158Z"
        style="fill-rule:nonzero;"
      />
    </g>
    <g transform="matrix(288,0,0,288,993.903,454.918)">
      <path
        d="M0.023,-0.741L0.023,-0.628C0.107,-0.628 0.124,-0.596 0.124,-0.527L0.124,-0.423C0.124,-0.369 0.148,-0.32 0.251,-0.299L0.251,-0.284C0.148,-0.263 0.124,-0.214 0.124,-0.16L0.124,-0.056C0.124,0.012 0.107,0.045 0.023,0.045L0.023,0.158C0.167,0.158 0.269,0.134 0.269,-0.041L0.269,-0.12C0.269,-0.192 0.297,-0.223 0.378,-0.223L0.378,-0.36C0.297,-0.36 0.269,-0.391 0.269,-0.463L0.269,-0.542C0.269,-0.717 0.167,-0.741 0.023,-0.741Z"
        style="fill-rule:nonzero;"
      />
    </g>
  </g>
</svg>
