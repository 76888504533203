<style>
  .marketing-block {
    padding: var(--space-2x);
    background: linear-gradient(to top, white, transparent), url('bg.jpg');
    background-position: top right -100px;
    background-repeat: no-repeat;
  }

  .marketing-block__inner-container {
    width: var(--space-72x);
  }

  .marketing-block__title {
    margin: 0;
    margin-bottom: var(--space-4x);
    line-height: 1.2;
    font-size: var(--text-08);
  }

  .marketing-block__description {
    margin-bottom: var(--space-4x);
  }

  .marketing-block__ask-us {
    font-weight: 200;
    font-style: italic;
  }
</style>

<div class="marketing-block">
  <div class="marketing-block__inner-container">
    <h2 class="marketing-block__title">
      Uncover trends and insights about your product's telemetry probes.
    </h2>
    <div class="marketing-block__description">
      Currently serving: Firefox Desktop, Firefox Android.
    </div>
    <div class="marketing-block__ask-us">
      Questions? Bugs? Requests? Find us at <a
        href="https://mozilla.slack.com/archives/CB1EQ437S">#glam</a
      >
      on Mozilla's internal Slack, or
      <a href="https://matrix.to/#/#datatools:mozilla.org">#datatools</a> on Mozilla's
      Matrix.
    </div>
  </div>
</div>
