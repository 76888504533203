<style>
  @keyframes pulse {
    0%,
    100% {
      fill: var(--cool-gray-subtle);
    }
    50% {
      fill: var(--cool-gray-100);
    }
  }

  svg {
    fill: var(--cool-gray-subtle);
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
</style>

<svg
  width="330"
  height="100%"
  viewBox="0 0 335 170"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  xmlns:serif="http://www.serif.com/"
  style="
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
  "
>
  <g transform="matrix(1,0,0,1,-197,-449)">
    <g transform="matrix(1.00601,0,0,1,-3.1952,0)">
      <rect rx="8" x="199" y="449" width="333" height="103" />
    </g>
    <rect rx="3" x="197" y="564" width="159" height="12" />
    <g transform="matrix(1,0,0,1.15385,0,-92.1538)">
      <rect rx="5" x="197" y="587" width="295" height="12" />
    </g>
    <g rx="5" transform="matrix(1,0,0,0.8,0,123)">
      <rect rx="5" x="197" y="605" width="84" height="15" />
    </g>
  </g>
</svg>
